import React from 'react'
import { graphql } from 'gatsby'
import Carousel from '../components/Carousel/Carousel'
import HTMLContent from '../wal-common/src/components/HTMLContent/HTMLContent'
import Layout from '../components/Layout/Layout'
import Tiles from '../components/RotatedTile/Tiles/Tiles'

export const IndexPage = ({ title, html, content }) => {
  return (
    <Layout title={title}
            noSection={<Carousel/>}>
      <HTMLContent html={html}
                   content={content}/>

      <div className="m-t-xl">
        <Tiles/>
      </div>
    </Layout>
  )
}

export default ({ data: { markdownRemark: page } }) => {
  return <IndexPage title={page.frontmatter.title}
                    html={page.htmlAst}/>
}

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            htmlAst
            html
            frontmatter {
                title
            }
        }
    }
`
