import React, { Component } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import RotatedTile from '../RotatedTile'
import './Tiles.scss'

const rotates = ['ccw5', 'cw7', 'ccw2', null, 'cw5', 'cw2']

const tilesConfig = [
  {
    to: '/anfahrt',
    title: 'Anfahrt & Kontakt',
    desc: 'So finden Sie mich...',
  },
  {
    to: '/leistungen-und-preise',
    title: 'Angebot des Herbstes',
    desc: '15% Rabatt bei einzelnen Behandlung/Gutscheinen',
  },
  {
    to: '/leistungen-und-preise',
    title: 'Aktuelle Preisliste gibt es hier...',
  },
  {
    to: '/ueber-mich',
    title: 'Über mich',
  },
  {
    to: '/kundenerfahrungen',
    title: 'Ihre Erfahrungen',
    desc: 'Kundenerfahrungen',
  },
  {
    to: '/fotoalbum',
    title: 'Fotos',
    desc:
      'Hier sind einigen Fotos von meinem Entspannungsraum und individuellen Relax-Sessions',
  },
]

class Tiles extends Component {
  render() {
    return (
      <div className="Tiles columns is-gapless is-multiline">
        {tilesConfig.map((t, i) => (
          <div key={t.title}
               className="column is-one-third">
            <RotatedTile
              {...t}
              file={`tile${i + 1}.png`}
              rotate={rotates[i]}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default Tiles
