import bulmaCarousel from 'bulma-extensions/bulma-carousel/dist/js/bulma-carousel'
import React, { Component } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import CarouselItem from './CarouselItem/CarouselItem'
import BEMHelper from 'react-bem-helper'

import './Carousel.scss'

const classes = new BEMHelper({
  name: 'Carousel',
})

class Carousel extends Component {
  componentDidMount() {
    bulmaCarousel.attach()
  }

  render() {
    return (
      <div
        {...classes(
          null,
          null,
          'carousel carousel-animated carousel-animate-slide'
        )}
        data-autoplay="true"
        data-stopautoplayoninteraction="true"
      >
        <div className="carousel-container">
          <CarouselItem
            file="title_carousel_1.png"
            title="Entspannung bringt neue Farben ins Leben!"
          />
          <CarouselItem
            file="title_carousel_2.png"
            title="Individuelle Relaxsession gegen Alltag-Stress!"
          />
          <CarouselItem
            file="title_carousel_3.png"
            title="MNUSHI - Entspannungstechnik für Zwei!"
          />
          <CarouselItem
            file="title_carousel_4.png"
            title="MNUSHI lernen und in der Familie anwenden!"
          />
          <CarouselItem
            file="title_carousel_5.png"
            title="Klassische Entspannungsmethoden"
            desc="Autogenes Training, Progressive Muskelentspannung, Aromen, Massagen, Meditation/Fantasiereise - alles einzeln oder in guter Kombination ganz individuell oder in der Kleingruppen."
          />
        </div>
        <div {...classes('Navi', null, 'carousel-navigation is-overlay')}>
          <div
            className="carousel-nav-left has-shadow"
            style={{
              padding: 0,
            }}
          >
            <FaChevronLeft />
          </div>
          <div
            className="carousel-nav-right has-shadow"
            style={{
              padding: 0,
            }}
          >
            <FaChevronRight />
          </div>
        </div>
      </div>
    )
  }
}

export default Carousel
