import React from 'react'
import { Component } from 'react'
import BEMHelper from 'react-bem-helper'

import './CarouselItem.scss'
import Image from '../../../wal-common/src/components/Image/Image'

const classes = new BEMHelper({
  name: 'CarouselItem',
});

class CarouselItem extends Component {
  render() {
    const {src, title, desc, file} = this.props

    return <div {...classes(null, null, ['carousel-item', 'has-background'])}>
      <Image
        file={file}
        className="is-background"
      />
      <div {...classes('textBox')}>
        <div {...classes('title')}>
          <h1 className="is-size-5 is-size-3-tablet is-size-1-desktop has-text-white is-family-secondary is-sit">
            {title}
          </h1>
        </div>
        {desc && <>
          <p {...classes('desc', null, 'box is-size-7 is-size-5-tablet')}>
            {desc}
          </p>
        </>}
      </div>
    </div>
  }
}

export default CarouselItem