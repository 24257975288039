import { Link } from 'gatsby'
import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'

import './RotatedTile.scss'
import Blender from '../../wal-common/src/components/Blender/Blender'
import ImageContainer from '../../wal-common/src/components/Image/ImageContainer/ImageContainer'

const classes = new BEMHelper({
  name: 'RotatedTile',
})

class RotatedTile extends Component {
  to

  state = {
    uncovered: false,
  }

  toggleState = () => {
    clearTimeout(this.to)

    this.setState({
      uncovered: !this.state.uncovered,
    })

    this.setTo(this.state.uncovered ? 3000 : undefined)
  }

  setTo(offset = 2000) {
    this.to = setTimeout(this.toggleState, offset + Math.random() * 1000)
  }

  componentDidMount() {
    this.setTo()
  }

  componentWillUnmount() {
    clearTimeout(this.to)
  }

  render() {
    const { file, rotate, title, desc, to } = this.props
    const { uncovered } = this.state

    return (
      <div {...classes(null, [rotate, uncovered ? 'uncovered' : null])}>
        <ImageContainer file={file}/>
        <Link to={to}>
          <Blender visible={uncovered}>
            <div {...classes('textBlock', null, 'p-l-md p-r-md')}>
              <h3
                {...classes(
                  'title',
                  [],
                  'title is-3 is-size-4-tablet is-size-3-desktop',
                )}
              >
                {title}
              </h3>
              {desc && (
                <>
                  <div className="is-divider"/>
                  <p
                    {...classes(
                      'desc',
                      null,
                      'is-size-7-tablet is-size-6-desktop',
                    )}
                  >
                    {desc}
                  </p>
                </>
              )}
            </div>
          </Blender>
        </Link>
      </div>
    )
  }
}

export default RotatedTile
